import Constants from 'expo-constants';
import { Platform } from 'react-native';

import { VERSION } from '../src/version';

export const configs = {
	debug: process.env.NODE_ENV !== 'production',
	development: process.env.NODE_ENV !== 'production',

	// 'theme.overrides': {
	// 	palette: {
	// 		background: { default: '#f4f5f7' },
	// 		primary: { main: 'black' },
	// 	},
	// },

	// App Developer
	author: 'BlueEast',

	// Website URL of App Developer
	authorUrl: 'https://www.blueeast.com',

	// App Title
	title: 'Mevris',

	// App Version
	version: VERSION,

	//  customer support phone number
	'plugin.settings-app.support.call.number': '+9242-111635635',

	/** 8:00 am in Pakistan is 3:00 am in UTC */
	'plugin.settings-app.support.call.opens': 3,

	/** 5:00 pm in Pakistan is noon in UTC */
	'plugin.settings-app.support.call.closes': 12,

	//  customer support email address
	'plugin.settings-app.support.email': 'help@mevris.com',

	// Apollo Graphql Configs
	'plugin.apollo.httpLinkOptions': {
		uri: Constants.expoConfig?.extra?.graphqlUrl ?? 'https://api.mevris.app/graphql',
	},

	'plugin.apollo.wsLinkOptions': {
		url: Constants.expoConfig?.extra?.graphqlWsUrl ?? 'wss://api.mevris.app/graphql',
	},

	// Google Map Key
	'google-maps-api-key': Constants.expoConfig?.extra?.googleMapsApiKey || null,

	// Config Persist Cache
	'plugin.config-persist.key': 'mevris-config',
	// 'plugin.config-persist.encryptionEnable': true,
	// 'plugin.config-persist.encryptionKey': '123',

	// eslint-disable-next-line max-len
	'user-management.portal.url': Constants.expoConfig?.extra?.accountPortalUrl ?? 'https://accounts.mevris.app/realms/main/account/',

	'user-management.keycloak': {
		url: Constants.expoConfig?.extra?.keycloak?.url ?? 'https://accounts.mevris.app',
		realm: Constants.expoConfig?.extra?.keycloak?.realm ?? 'main',
		scheme: Constants.expoConfig?.extra?.keycloak?.scheme ?? 'mevris',
		clientId: Platform.select({
			android: Constants.expoConfig?.extra?.keycloak?.androidClientId ?? 'mevris-android-app',
			ios: Constants.expoConfig?.extra?.keycloak?.iosClientId ?? 'mevris-ios-app',
			web: Constants.expoConfig?.extra?.keycloak?.webClientId ?? 'mevris-web-app',
		}),
	},

	'userManagement.source': 'keycloak', // 'logto' | 'keycloak'
	// 'userManagement.url': 'http://localhost:3001', // admin:_lg7YFU5
	// 'userManagement.clientId': 'wyla31o31osffbivckmjp',
	// 'userManagement.scopes': 'openid profile email offline_access phone custom_data roles',
	// 'userManagement.resource': 'https://api.mevris.app',
};
